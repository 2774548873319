import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const UserProfileDetailDocument = gql `
    query userProfileDetail {
  userProfileDetail {
    authorities
    email
    enabled
    firstName
    fullName
    inactiveEmails
    lastLoggedIn
    lastName
    locked
    login
    priceLists
    priceListsLoaded
    priceListsValidUntil
    stocks
    stocksLoaded
    stocksValidUntil
  }
}
    `;
/**
 * __useUserProfileDetailQuery__
 *
 * To run a query within a React component, call `useUserProfileDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileDetailQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(UserProfileDetailDocument, options);
}
export function useUserProfileDetailLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(UserProfileDetailDocument, options);
}
export function useUserProfileDetailSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(UserProfileDetailDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_UserProfileDetailQuery = async function (options) {
    return getGqlData({
        ...options,
        query: UserProfileDetailDocument,
    });
};
