'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useResendNewEmailActivationMutation } from '../../graphql/user-profile/requests/resendNewEmailActivation.codegen';
function ResendNewEmailActivationButton({ children, inactiveEmail, login, }) {
    const [resendNewEmailActivation, { loading }] = useResendNewEmailActivationMutation();
    function handleResendEmailActivation() {
        resendNewEmailActivation({
            variables: {
                input: {
                    email: inactiveEmail,
                    login,
                },
            },
        });
    }
    return (_jsx(Button, { design: "quaternary", onClick: handleResendEmailActivation, loading: loading, disabled: loading, children: children }));
}
export default ResendNewEmailActivationButton;
