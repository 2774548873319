import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { accordionClassNames } from '@edeeone/juan-core/components/accordion/accordion.styles';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { Fragment, useId } from 'react';
export const Accordion = ({ styles, items }) => {
    const finalStyles = accordionClassNames(styles);
    const id = useId();
    return (_jsx("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: items.map(({ heading, content, headingSecondary, iconPlus = CONFIG?.componentSettings?.juanCore?.Accordion
            ?.iconPlus ?? 'plus', iconMinus = CONFIG?.componentSettings?.juanCore?.Accordion
            ?.iconMinus ?? 'minus', }, i) => {
            return (_jsx(Fragment, { children: content && (_jsxs("details", { className: finalStyles.details, children: [_jsxs("summary", { className: finalStyles.summary, children: [_jsx(SvgSpriteIcon, { icon: iconMinus, className: twMergeJuan(finalStyles.icon, finalStyles.iconMinus) }), _jsx(SvgSpriteIcon, { icon: iconPlus, className: twMergeJuan(finalStyles.icon, finalStyles.iconPlus) }), _jsx("span", { className: finalStyles.heading, children: heading }), headingSecondary && (_jsxs("span", { className: finalStyles.subheading, children: ["(", headingSecondary, ")"] }))] }), _jsx("div", { className: twMergeJuan(finalStyles.content, finalStyles.content__custom), children: content })] })) }, `${id}-accordion-${i}`));
        }) }));
};
