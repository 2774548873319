import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const loginFormDefaultClassNames = /* tw */ {
    root: 'login-form-page',
    root__full: 'max-content-wider pt-20 xl:pt-40 text-center',
    root__modal: '',
    form: 'login-form text-left xsm:w-full xsm:max-content-narrow flex flex-col',
    form__full: '',
    form__modal: 'mx-6 xsm:mx-auto',
    title: 'login-form__title text-center',
    title__full: '!mt-0',
    title__modal: 'mb-8',
    alertContainer: 'login-form__alert-container mb-12',
    socialLoginContent: 'login-form__socials flex flex-col gap-2 mb-6',
    socialLogin_ico: '!w-8 !h-8',
    formContent: 'login-form__content',
    formContent_title: "login-form__title text-gray-30 text-center text-xs mb-12 relative before:w-full before:content-[' '] before:h-0.5 before:absolute before:bg-gray-20 before:left-0 before:top-1/2 before:-translate-y-1/2 before:z-0",
    formContent_title_content: 'bg-white inline-block px-4 relative z-1',
    checkboxContainer: 'login-form__checkbox-container flex justify-between mb-12',
    checkboxLink: '',
    checkboxLink_a: 'text-black underline hover:no-underline transition-common text-xs',
    btnContainer: 'login-form__buttons w-full flex mb-12 flex-col',
    note: 'text-xs text-gray-60 text-center block py-6 border-t border-gray-20',
    note_a: 'text-black underline transition-common hover:no-underline',
};
export function loginFormClassNames(...args) {
    return getMemoizedFinalClassnames('loginFormClassNames', loginFormDefaultClassNames, ...args);
}
