import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const ResendNewEmailActivationDocument = gql `
    mutation resendNewEmailActivation($input: ResendNewEmailActivationInput) {
  resendNewEmailActivation(input: $input) {
    login
    email
  }
}
    `;
/**
 * __useResendNewEmailActivationMutation__
 *
 * To run a mutation, you first call `useResendNewEmailActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendNewEmailActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendNewEmailActivationMutation, { data, loading, error }] = useResendNewEmailActivationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendNewEmailActivationMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(ResendNewEmailActivationDocument, options);
}
