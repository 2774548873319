import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useSearchParams } from 'next/navigation';
export const useVariantParam = () => {
    const { pathname } = useLocation();
    const searchParams = useSearchParams();
    const variantParamKey = CONFIG.evita.staticSearchParameter;
    const variantParam = variantParamKey
        ? { [variantParamKey]: getSingleValue(searchParams.get(variantParamKey)) }
        : {};
    return { pathname, variantParam };
};
const getSingleValue = (value) => {
    if (!value) {
        return null;
    }
    return Array.isArray(value) ? value[0] : value;
};
