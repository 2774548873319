'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { imageClassNames, } from '@edeeone/juan-core/components/image/image.styles';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import NextImage from 'next/image';
export const imagePlaceholder = process.env.IMAGE_PLACEHOLDER;
/**
 * @description dont use this component, use `Image` component instead
 */
export const ImageCl = function ({ modified, apiId, src = process.env.IMAGE_PLACEHOLDER, placeholder = CONFIG?.componentSettings?.juanCore?.ImageCl?.placeholder ??
    'blur', blurDataURL = CONFIG?.componentSettings?.juanCore?.ImageCl?.blurDataURL ??
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8Vg8AAlEBZ0IDjCQAAAAASUVORK5CYII=', styles, design, aspectRatio, className, renderPlaceholder = true, ...props }) {
    if (modified) {
        src = `/ver/${new Date(modified).getTime() + 2}${src}`;
    }
    if (!src && !renderPlaceholder) {
        return null;
    }
    if (!src) {
        src = placeholder;
    }
    const finalStyles = imageClassNames(styles, design);
    return (_jsx(NextImage, { src: src, placeholder: placeholder, blurDataURL: blurDataURL, width: props.width, height: props.height, alt: props.alt || '', className: twMergeJuan(className, finalStyles.content, finalStyles.content__custom, aspectRatio && `aspect-${aspectRatio}`), ...props }));
};
