import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const imageDefaultClassNames = /* tw */ {
    content: 'motive',
    content__custom: '',
    // výchozí motiv
    primary: {},
    // motiv s šířkou 100 %
    secondary: {
        content: 'w-full',
    },
};
export function imageClassNames(...args) {
    return getMemoizedFinalClassnames('imageClassNames', imageDefaultClassNames, ...args);
}
