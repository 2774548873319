import { useUserProfileDetailQuery } from '../graphql/user-profile/requests/userProfileDetail.codegen';
export const useUser = function () {
    const { data, ...rest } = useUserProfileDetailQuery({
        context: {
            tags: ['userProfile'],
        },
    });
    return {
        user: data?.userProfileDetail,
        ...rest,
    };
};
