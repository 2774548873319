'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion } from '@edeeone/juan-core/components/accordion/Accordion';
import { Badge } from '@edeeone/juan-core/components/badge/Badge';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { whyRegisterClassNames, whyRegisterSettings, } from './WhyRegister.styles';
export function WhyRegister({ design = 'primary', styles }) {
    const { t } = useI18n('WhyRegister');
    const finalStyles = whyRegisterClassNames(styles, design);
    const finalSettings = whyRegisterSettings;
    return (_jsxs(_Fragment, { children: [design === 'primary' && (_jsx(Accordion, { styles: { container__custom: finalStyles.container }, items: [
                    {
                        content: (_jsx("ul", { className: finalStyles.list, children: [
                                {
                                    title: t('whyRegister1'),
                                    icon: `${finalSettings.icons.primary.icon1}`,
                                },
                                {
                                    title: t('whyRegister2'),
                                    icon: `${finalSettings.icons.primary.icon2}`,
                                },
                                {
                                    title: t('whyRegister3'),
                                    icon: `${finalSettings.icons.primary.icon3}`,
                                },
                            ].map((it, idx) => {
                                return (_jsxs("li", { className: finalStyles.item, children: [_jsx(SvgSpriteIcon, { icon: it.icon, ariaHidden: true, className: finalStyles.icon }), it.title] }, `_${idx}`));
                            }) })),
                        heading: _jsx(_Fragment, { children: t('whyRegisterTitle') }),
                    },
                ] })), design === 'secondary' && (_jsxs("div", { className: finalStyles.container, children: [' ', _jsxs("ul", { className: finalStyles.list, children: [_jsxs("li", { className: finalStyles.item, children: [_jsx(SvgSpriteIcon, { icon: finalSettings.icons.secondary.icon1, ariaHidden: true, className: finalStyles.icon }), t('whyRegister1')] }), _jsxs("li", { className: finalStyles.item, children: [_jsx(SvgSpriteIcon, { icon: finalSettings.icons.secondary.icon2, ariaHidden: true, className: finalStyles.icon }), t('whyRegister2')] }), _jsxs("li", { className: finalStyles.item, children: [_jsx(SvgSpriteIcon, { icon: finalSettings.icons.secondary.icon3, ariaHidden: true, className: finalStyles.icon }), t('whyRegister3')] })] })] })), design === 'tertiary' && (_jsx("div", { className: finalStyles.container, children: _jsxs("ul", { className: finalStyles.list, children: [_jsxs("li", { className: finalStyles.item, children: [_jsxs("div", { className: finalStyles.iconContainer, children: [_jsx(SvgSpriteIcon, { icon: finalSettings.icons.tertiary.icon1, ariaHidden: true, className: finalStyles.icon }), _jsx(Badge, { size: "sm", border: true, display: "positioned", styles: {
                                                container__custom: finalStyles.badge,
                                            }, children: "1" })] }), _jsx("div", { className: finalStyles.text, children: t('whyRegister1') })] }), _jsxs("li", { className: finalStyles.item, children: [_jsxs("div", { className: finalStyles.iconContainer, children: [_jsx(SvgSpriteIcon, { icon: finalSettings.icons.tertiary.icon2, ariaHidden: true, className: finalStyles.icon }), _jsx(Badge, { size: "sm", border: true, display: "positioned", styles: {
                                                container__custom: finalStyles.badge,
                                            }, children: "2" })] }), _jsx("div", { className: finalStyles.text, children: t('whyRegister2') })] }), _jsxs("li", { className: finalStyles.item, children: [_jsxs("div", { className: finalStyles.iconContainer, children: [_jsx(SvgSpriteIcon, { icon: finalSettings.icons.tertiary.icon3, ariaHidden: true, className: finalStyles.icon }), _jsx(Badge, { size: "sm", border: true, display: "positioned", styles: {
                                                container__custom: finalStyles.badge,
                                            }, children: "3" })] }), _jsx("div", { className: finalStyles.text, children: t('whyRegister3') })] })] }) }))] }));
}
export default WhyRegister;
