import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const tabDefaultClassNames = /* tw */ {
    tab: 'text-left px-0 flex content-center relative font-medium gap-4 items-center justify-center md:text-center transition-common text-gray-60 hover:text-black w-full',
    active: {
        tab: 'relative after:w-full after:h-[2px] after:bg-green-light after:absolute after:left-0 after:bottom-0 text-black font-semibold after:block after:absolute after:w-full after:-bottom-0.5',
    },
    icon: 'w-6 h-6',
    // výchozí záložka
    primary: {
        tab: 'py-6',
    },
    // záložka v modálním okně (přihlášení/registrace)
    secondary: {
        tab: 'pt-6 pb-3',
    },
    // záložka v modálním okně (výběr odběrného místa)
    tertiary: {
        tab: 'py-6',
    },
};
export function tabClassNames(...args) {
    return getMemoizedFinalClassnames('tabClassNames', tabDefaultClassNames, ...args);
}
