'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PasswordInput } from '@edeeone/edee-registration/components/PasswordInput';
import { useUser } from '@edeeone/edee-registration/components/useUser';
import { registrationLoginSchema } from '@edeeone/edee-registration/graphql/registrationLoginSchema';
import loginServerAction from '@edeeone/edee-registration/serverActions/login.serverAction';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { Form } from '@edeeone/juan-core/components/form/Form';
import { CheckboxGroup } from '@edeeone/juan-core/components/form/checkbox/CheckboxGroup';
import { FormGroup } from '@edeeone/juan-core/components/form/formGroup/FormGroup';
import { Input } from '@edeeone/juan-core/components/form/input/Input';
import { Title } from '@edeeone/juan-core/components/title/Title';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorAlert } from './ErrorAlert';
import { loginFormClassNames } from './LoginForm.styles';
import { RegistrationSection } from './RegistrationSection';
import { ResetPasswordSection } from './ResetPasswordSection';
import { getErrorType } from './getErrorType';
export const LoginForm = ({ styles, design, inModal = false, isServerModal = false, onComplete, action, }) => {
    const { t } = useI18n('LoginForm');
    const finalStyles = loginFormClassNames(styles, design);
    const { refetch: refetchUser } = useUser();
    const [resolvedError, setResolvedError] = useState(null);
    const [captchaCode, setCaptchaCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setData] = useState(null);
    const [error, setError] = useState(null);
    const { email: emailFromUrl } = Object.fromEntries(useSearchParams());
    const defaultValues = {
        login: emailFromUrl,
    };
    useEffect(() => {
        if (!error) {
            setResolvedError(null);
            return;
        }
        const errorType = getErrorType(error);
        setResolvedError({
            type: errorType,
            title: `${errorType}.title`,
            description: `${errorType}.description`,
        });
    }, [user, loading, error]);
    const isCaptchaErrorWithoutCode = resolvedError?.type === 'captchaError' && captchaCode == null;
    const showReCaptcha = CONFIG.googleRecaptcha?.v2?.checkboxSiteKey && isCaptchaErrorWithoutCode;
    const showSocialSitesRegistration = !!CONFIG.registration.socialSites?.length;
    const handleSubmit = async (submitData) => {
        setLoading(true);
        const res = await loginServerAction({
            ...submitData,
            captchaCode: captchaCode || undefined,
            remember: Array.isArray(submitData.remember) && submitData.remember.length > 0,
        });
        await refreshTags(['userProfile']);
        setLoading(false);
        setData(res.data);
        setError(res.error);
        refetchUser();
        setCaptchaCode(null);
        if (!res?.data) {
            return;
        }
        onComplete?.();
    };
    return (_jsxs("div", { className: twMergeJuan(finalStyles.root, !inModal && finalStyles.root__full, inModal && finalStyles.root__modal), children: [_jsx(Title, { design: inModal ? 'quaternary' : 'primary', as: inModal ? 'h2' : undefined, styles: {
                    container__custom: twMergeJuan(finalStyles.title, finalStyles[inModal ? 'title__modal' : 'title__full']),
                }, children: t('title') }), _jsxs(Form, { id: "LoginForm", styles: {
                    form: twMergeJuan(finalStyles.form, inModal && finalStyles.form__modal, !inModal && finalStyles.form__full),
                }, defaultValues: defaultValues, onSubmit: handleSubmit, schema: registrationLoginSchema, children: [_jsx(ErrorAlert, { styles: styles, design: design, alertSize: inModal ? 'md' : 'lg', error: resolvedError }), showSocialSitesRegistration && (_jsx("div", { className: finalStyles.socialLoginContent, children: CONFIG.registration.socialSites?.map((site) => {
                            return (_jsx(Button, { design: "secondary", icon: site, type: "button", size: "xsm", styles: {
                                    icon__custom: finalStyles.socialLogin_ico,
                                }, href: `/registration/secure/performLogin/${site}`, nativeHref: true, children: t(`signIn${site}`) }, site));
                        }) })), _jsxs("div", { className: finalStyles.formContent, children: [showSocialSitesRegistration && (_jsx("p", { className: finalStyles.formContent_title, children: _jsx("span", { className: finalStyles.formContent_title_content, children: t('signInEmail') }) })), _jsx(FormGroup, { name: "login", label: t('email'), children: _jsx(Input, { type: "email" }) }), _jsx(PasswordInput, { variableName: "password", showPasswordStrength: false }), _jsxs("div", { className: finalStyles.checkboxContainer, children: [_jsx(CheckboxGroup, { name: "remember", items: [{ label: t('rememberMe'), value: 'true' }] }), _jsx(ResetPasswordSection, { styles: styles, design: design, inModal: inModal, isServerModal: isServerModal, action: action })] }), _jsxs("div", { className: finalStyles.btnContainer, children: [showReCaptcha && (_jsx(ReCAPTCHA, { sitekey: CONFIG.googleRecaptcha?.v2?.checkboxSiteKey, onChange: (data) => {
                                            setCaptchaCode(data);
                                        } })), !isCaptchaErrorWithoutCode && (_jsx(Button, { type: "submit", disabled: Boolean(loading || user), loading: loading, design: user ? 'success' : 'primary', icon: user ? 'checkmark' : undefined, children: user ? t('loggedIn') : t('loginSubmit') }))] }), _jsx(RegistrationSection, { styles: styles, design: design, inModal: inModal, isServerModal: isServerModal, action: action })] })] })] }));
};
