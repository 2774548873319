import { jsx as _jsx } from "react/jsx-runtime";
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { getHref } from '@edeeone/string/getHref';
import Link from 'next/link';
export const LinkRoute = function ({ children, id, webId, query, params, ...props }) {
    const route = useLinkRoute({
        webId,
        id,
        params,
    });
    const child = children || route?.name;
    return (_jsx(Link, { href: getHref(route?.url, query), ...props, children: child }));
};
