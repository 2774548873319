import { useParams, usePathname } from 'next/navigation';
// TODO tests a zymyslet se jestli je správně.
export const useLocationPathname = function () {
    const pathname = usePathname();
    const params = useParams();
    const bag = pathname.split('/').slice(1);
    if (bag[1] !== 'juan') {
        return pathname;
    }
    if (params.region && params.slug) {
        return `/${Array.isArray(params.slug) ? params.slug.join('/') : params.slug}`;
    }
    return pathname;
};
