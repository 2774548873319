export const registrationLoginSchema /*JSONSchemaType<Required<LoginInput>>*/ = {
    type: 'object',
    properties: {
        login: {
            type: 'string',
            isRequired: true,
            isEmail: true,
        },
        password: {
            type: 'string',
            isRequired: true,
        },
        // TODO JTR - array až se vyřeší render checkboxu
        // remember: {
        //   type: 'boolean',
        // },
    },
    additionalProperties: true,
};
