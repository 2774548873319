'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useVariantParam } from '@edeeone/edee-registration/hooks/useVariantParam';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useServerActionModal } from '@edeeone/juan-core/components/serverActionModal/useServerActionModal';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { LinkRoute } from '@edeeone/juan-core/routes/LinkRoute';
import { linkOnClickShallow } from '@edeeone/juan-core/utils/linkOnClickShallow';
import { getHref } from '@edeeone/string/getHref';
import Link from 'next/link';
import { loginFormClassNames } from './LoginForm.styles';
export const RegistrationSection = ({ styles, design, inModal, isServerModal, action, }) => {
    const { t } = useI18n('LoginForm');
    const finalStyles = loginFormClassNames(styles, design);
    const { pathname, variantParam } = useVariantParam();
    const { triggerModalServerAction } = useServerActionModal();
    const handleOpenRegister = (e) => {
        e.stopPropagation();
        if (!action) {
            return;
        }
        const rating = action.bind(null, {
            actionType: 'REGISTER',
        });
        triggerModalServerAction(rating);
    };
    return (_jsx("p", { className: finalStyles.note, children: t('noAccount', {
            a: (chunks) => {
                if (isServerModal) {
                    return (_jsx(Button, { design: "quaternary", className: finalStyles.note_a, onClick: handleOpenRegister, children: chunks }));
                }
                if (inModal) {
                    return (_jsx(Link, { href: getHref(pathname, { ...variantParam, user: 2 }), onClick: linkOnClickShallow, className: finalStyles.note_a, children: chunks }));
                }
                return (_jsx(LinkRoute, { id: '/shop-user/registration', legacyBehavior: true, children: _jsx("a", { className: finalStyles.note_a, children: chunks }) }));
            },
        }) }));
};
