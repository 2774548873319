'use client';
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useFormState } from 'react-hook-form';
import { useFormStatus } from './Form';
export const FormContent = ({ hideOnSuccess = true, children, }) => {
    // NOTE: we can't use isValid from useFormState because it will always derived via the entire form validation result.
    // https://react-hook-form.com/docs/useformstate
    const { errors, isSubmitSuccessful } = useFormState();
    const isValid = Object.keys(errors).length === 0;
    const { successStatus } = useFormStatus();
    const showFormContent = hideOnSuccess
        ? (!isSubmitSuccessful && !successStatus.success) || !isValid
        : true;
    return _jsx(_Fragment, { children: showFormContent && children });
};
