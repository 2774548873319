import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { Orientation } from '../../../types';
import { Checkbox } from './Checkbox';
import { checkboxGroupClassNames } from './checkboxGroup.styles';
const ConditionalWrapper = ({ condition, wrapper, children }) => {
    return condition ? wrapper(children) : children;
};
export const CheckboxGroup = ({ id, name, 
// onChange,
// onBlur,
styles, items = [], design, orientation = Orientation.VERTICAL, standalone = false, size, noResult = '', style,
// value,
 }) => {
    const calculatedId = useId();
    const finalId = id || calculatedId;
    const { register, setValue, watch } = useFormContext();
    register(name);
    const fieldValue = watch(name);
    const finalStyles = checkboxGroupClassNames(styles, design, size, {
        horizontal: orientation === Orientation.HORIZONTAL,
    });
    const handleChange = useCallback((_value, _name, checked, checkedValue) => {
        let newValue = Array.isArray(fieldValue)
            ? [...fieldValue]
            : fieldValue == null
                ? []
                : [fieldValue];
        if (checked) {
            newValue = [
                ...newValue,
                ...(Array.isArray(checkedValue) ? checkedValue : [checkedValue]),
            ];
        }
        else {
            newValue = newValue.filter((item) => {
                return item !== checkedValue;
            });
        }
        newValue = newValue.length === 0 ? undefined : newValue;
        setValue(name, newValue);
    }, [name, fieldValue, setValue]);
    return (_jsx(ConditionalWrapper, { condition: !standalone, wrapper: (children) => {
            return (_jsx("div", { className: twMergeJuan(finalStyles.group, finalStyles.group__custom), id: finalId, style: style, children: children }));
        }, children: _jsxs(_Fragment, { children: [items.map(({ label, value: optionValue, disabled, count, children }, index) => {
                    return (_jsx(Checkbox, { style: standalone ? style : undefined, id: `${finalId}-${index}`, disabled: disabled, design: design, label: label, count: count, onChange: handleChange, checkedValue: optionValue, value: fieldValue?.find((item) => {
                            return item === optionValue;
                        }), children: children }, `${finalId}-${index}`));
                }), !items.length && _jsx("p", { className: finalStyles.noResult, children: noResult })] }) }));
};
