import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const accordionDefaultClassNames = /* tw */ {
    container: 'accordion flex flex-col',
    container__custom: '',
    details: 'accordion__detail border-b border-gray-10 first-of-type:border-t',
    summary: 'accordion__summary group/summary flex gap-6 list-style-none items-center py-[17px] px-4 cursor-pointer text-black hover:text-gray-70 transition-common',
    icon: 'accordion__ico w-[1.2rem] h-[1.2rem] fill-black group-hover/summary:fill-gray-70',
    iconPlus: 'ico--plus block',
    iconMinus: 'ico--minus hidden',
    heading: 'accordion__heading font-semibold text-sm',
    subheading: 'accordion__subheading font-semibold text-sm text-gray-30',
    content: 'accordion__content block px-6 py-4',
    content__custom: '',
};
export function accordionClassNames(...args) {
    return getMemoizedFinalClassnames('accordionClassNames', accordionDefaultClassNames, ...args);
}
