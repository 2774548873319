'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useVariantParam } from '@edeeone/edee-registration/hooks/useVariantParam';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useServerActionModal } from '@edeeone/juan-core/components/serverActionModal/useServerActionModal';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { LinkRoute } from '@edeeone/juan-core/routes/LinkRoute';
import { linkOnClickShallow } from '@edeeone/juan-core/utils/linkOnClickShallow';
import { getHref } from '@edeeone/string/getHref';
import Link from 'next/link';
import { useFormContext } from 'react-hook-form';
import { loginFormClassNames } from './LoginForm.styles';
export const ResetPasswordSection = ({ styles, design, inModal, isServerModal, action, }) => {
    const { t } = useI18n('LoginForm');
    const finalStyles = loginFormClassNames(styles, design);
    const { watch } = useFormContext();
    const { pathname, variantParam } = useVariantParam();
    const { triggerModalServerAction } = useServerActionModal();
    const email = watch('login');
    const forgottenPasswordQueryProp = email ? { query: { email } } : {};
    const label = t('forgottenPassword');
    const handleOpenResetPassword = (e) => {
        e.stopPropagation();
        if (!action) {
            return;
        }
        const rating = action.bind(null, {
            actionType: 'RESET_PASSWORD',
        });
        triggerModalServerAction(rating);
    };
    const showServerModalButton = isServerModal;
    const showModalLink = !isServerModal && inModal;
    const showLinkRoute = !isServerModal && !inModal;
    return (_jsxs("p", { className: finalStyles.checkboxLink, children: [showServerModalButton && (_jsx(Button, { design: "quaternary", className: finalStyles.checkboxLink_a, onClick: handleOpenResetPassword, children: label })), showModalLink && (_jsx(Link, { href: getHref(pathname, {
                    ...variantParam,
                    ...forgottenPasswordQueryProp.query,
                    user: 3,
                }), onClick: linkOnClickShallow, className: finalStyles.checkboxLink_a, children: label })), showLinkRoute && (_jsx(LinkRoute, { id: '/edee-registration/lostpassword', legacyBehavior: true, ...forgottenPasswordQueryProp, children: _jsx("a", { className: finalStyles.checkboxLink_a, children: label }) }))] }));
};
