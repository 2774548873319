import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const RegistrationLoginDocument = gql `
    mutation registrationLogin($input: RegistrationsLoginInput) {
  registrationLogin(input: $input) {
    error
    data {
      authorities
      created
      email
      firstName
      lastName
    }
  }
}
    `;
/**
 * __useRegistrationLoginMutation__
 *
 * To run a mutation, you first call `useRegistrationLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationLoginMutation, { data, loading, error }] = useRegistrationLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrationLoginMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(RegistrationLoginDocument, options);
}
export const RegistrationPasswordRecoveryDocument = gql `
    mutation registrationPasswordRecovery($input: RegistrationsPasswordRecoveryInput) {
  registrationPasswordRecovery(input: $input) {
    message
    success
  }
}
    `;
/**
 * __useRegistrationPasswordRecoveryMutation__
 *
 * To run a mutation, you first call `useRegistrationPasswordRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPasswordRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationPasswordRecoveryMutation, { data, loading, error }] = useRegistrationPasswordRecoveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegistrationPasswordRecoveryMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(RegistrationPasswordRecoveryDocument, options);
}
export const RegistrationLogoutDocument = gql `
    mutation registrationLogout {
  registrationLogout {
    message
    success
  }
}
    `;
/**
 * __useRegistrationLogoutMutation__
 *
 * To run a mutation, you first call `useRegistrationLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationLogoutMutation, { data, loading, error }] = useRegistrationLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegistrationLogoutMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(RegistrationLogoutDocument, options);
}
export const RegistrationsMeDocument = gql `
    query registrationsMe {
  registrationsMe {
    email
    firstName
    lastName
    authorities
    login
    enabled
    created
    lastChanged
    domain
    countryCode
    phone
    postCode
    priceLists
    priceListsValidUntil
    stocks
    isAnonymous
    isLogged
    hasSession
  }
}
    `;
/**
 * __useRegistrationsMeQuery__
 *
 * To run a query within a React component, call `useRegistrationsMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationsMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationsMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistrationsMeQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(RegistrationsMeDocument, options);
}
export function useRegistrationsMeLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(RegistrationsMeDocument, options);
}
export function useRegistrationsMeSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(RegistrationsMeDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_RegistrationsMeQuery = async function (options) {
    return getGqlData({
        ...options,
        query: RegistrationsMeDocument,
    });
};
