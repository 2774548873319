import { getMemoizedFinalClassnames } from '../../../hooks/useFinalClassnames';
export const checkboxGroupDefaultClassNames = /* tw */ {
    group: 'form-group form-group--checkbox-group flex flex-col gap-8',
    group__custom: '',
    noResult: 'form-group__no-result text-xs text-gray-70',
    tertiary: {
        group: 'max-h-[160px] overflow-y-auto custom-scrollbar',
    },
};
export function checkboxGroupClassNames(...args) {
    return getMemoizedFinalClassnames('checkboxGroupClassNames', checkboxGroupDefaultClassNames, ...args);
}
