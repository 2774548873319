'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useFormState } from 'react-hook-form';
import { useFormStatus } from './Form';
export const SubmitButton = ({ design: designProp, label: labelProp, i18nNamespace, enableSuccess = false, successLabel, successDesign, ...props }) => {
    const i18nNamespaceFinal = `Form${i18nNamespace ? `.${i18nNamespace}` : ''}`;
    const { t } = useI18n(i18nNamespaceFinal);
    const { isLoading } = useFormState();
    const { isLoading: isLoadingStatus, successStatus: { success }, } = useFormStatus();
    const label = enableSuccess && success ? successLabel : labelProp || t('submit');
    const design = enableSuccess && success ? successDesign : designProp;
    return (_jsx(Button, { ...props, design: design, type: "submit", loading: isLoading || isLoadingStatus, disabled: enableSuccess ? success : undefined, children: label || t('submit') }));
};
