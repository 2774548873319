'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from '@edeeone/juan-core/components/alert/Alert';
import { useFormState } from 'react-hook-form';
export const FormErrorAlert = ({ styles, size = 'md', }) => {
    const { errors } = useFormState();
    if (!errors.root) {
        return null;
    }
    return (_jsx(Alert, { type: "error", size: size, styles: styles, children: errors.root.message }));
};
