import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whyRegisterDefaultClassNames = /* tw */ {
    container: 'why-register',
    list: 'why-register__list flex flex-col gap-8',
    item: 'why-register__item flex gap-6',
    iconContainer: 'why-register__icon-container',
    icon: 'why-register__icon ico shrink-0',
    text: 'why-register__text',
    badge: 'why-register__badge',
    // výhody registrace v accordionu v modálu registrace/registrace firmy
    primary: {
        container: 'mb-8',
        list: 'text-xs text-gray-60',
        item: 'items-start',
        icon: 'w-8 h-8',
    },
    // výhody registrace v dropdownu v záhlaví
    secondary: {
        list: 'text-xs mt-6 text-gray-60',
        item: 'items-start',
        icon: 'w-7 h-7 mt-1',
    },
    // výhody registrace v boxu v potvrzení objednávky
    tertiary: {
        container: 'mb-12',
        item: 'items-center justify-start',
        iconContainer: 'relative',
        icon: 'w-10 h-10',
        text: 'text-left text-sm font-normal text-gray-70',
        badge: '!border-gray-10',
    },
};
export const whyRegisterSettings = {
    icons: {
        primary: {
            icon1: 'toolEye',
            icon2: 'toolHeart',
            icon3: 'toolTag',
        },
        secondary: {
            icon1: 'toolCheckbox',
            icon2: 'toolMonitor',
            icon3: 'toolTag',
        },
        tertiary: {
            icon1: 'toolCheckbox',
            icon2: 'toolMonitor',
            icon3: 'toolTag',
        },
    },
};
export function whyRegisterClassNames(...args) {
    return getMemoizedFinalClassnames('whyRegisterClassNames', whyRegisterDefaultClassNames, ...args);
}
