'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from '@edeeone/juan-core/components/alert/Alert';
import { useFormStatus } from '@edeeone/juan-core/components/form/Form';
import { useFormState } from 'react-hook-form';
export const FormSuccessAlert = ({ successMessageOverride, successNoteOverride: successNoteOverride, styles, size = 'md', actions, }) => {
    const { isSubmitSuccessful } = useFormState();
    const { successStatus: { success, message, note }, } = useFormStatus();
    if (!isSubmitSuccessful && !success) {
        return null;
    }
    const successMessage = successMessageOverride || message;
    const successNote = successNoteOverride || note || '';
    return (_jsxs(_Fragment, { children: [_jsx(Alert, { type: "success", size: size, styles: styles, text: _jsx("p", { children: successMessage }), note: successNote }), actions] }));
};
