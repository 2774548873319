'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import Link from 'next/link';
import { tabClassNames } from './tab.styles';
export const Tab = ({ styles, design = 'primary', icon, children, type = 'button', active, href, locale, scroll = false, renderAsNativeHref = false, shallow, ...props }) => {
    const finalStyles = tabClassNames(styles, design, undefined, { active });
    const tabContent = (_jsxs(_Fragment, { children: [icon && _jsx(SvgSpriteIcon, { className: finalStyles.icon, icon: icon }), children] }));
    if (href) {
        // JTR - https://gitlab.fg.cz/edee/edeeone/-/work_items/1061#note_503313 - next link requested server side rendering on every tab click - this is a workaround
        if (renderAsNativeHref) {
            return (_jsx("a", { role: 'tab', href: href, tabIndex: 0, className: finalStyles.tab, rel: "nofollow", children: tabContent }));
        }
        else {
            return (_jsx(Link, { role: 'tab', href: href, locale: locale, scroll: scroll, tabIndex: 0, className: finalStyles.tab, rel: "nofollow", onClick: (e) => {
                    if (shallow) {
                        e.preventDefault();
                        window.history.pushState({}, '', href);
                    }
                }, children: tabContent }));
        }
    }
    return (_jsx("button", { ...props, className: finalStyles.tab, children: tabContent }));
};
